<template>
	<div class="association" v-loading='loading'>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" placeholder="活动名称" style="width: 250px;margin-right: 10px;" clearable></el-input>
			</div>

			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<el-button type="primary" style="margin-left: 15px;" @click="getNewGift({},2)">添加</el-button>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="GiftData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys">
				<el-table-column label="活动名称" width="280">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<div style="min-height:auto;width:200px;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;width: 100%;">
									<div style="width: 100%;" class="ellipsisStyle">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
									</div>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="活动时间">
					<template slot-scope="scope">
						<div>{{scope.row.StartTime}}至</div>
						<div>{{scope.row.EndTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="ProductNumber" label="活动商品"></el-table-column>
				<el-table-column prop="HelpNumber" label="助力人数"></el-table-column>
				<el-table-column prop="ActivityStateShow" label="状态"></el-table-column>
				<el-table-column prop="ExchangeCount" label="已领取">
					<template slot-scope="scope">
						<div style="color:#409EFF;cursor:pointer;" @click='getExchangeCount(scope.row)'>{{scope.row.ExchangeCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="260">
					<template slot-scope="scope">
						<el-button type="text" class="pointers" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'"
						 @click="weiCodeFun(scope.row)" style="margin-right:10px;">推广</el-button>
						<button-permissions :datas="'Editassistindex'">
							<div class="table-button" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'" @click="getNewGift(scope.row,1)">编辑</div>
						</button-permissions>
						<button-permissions :datas="'Virtualassistindex'">
							<div class="table-button" style="color:#409EFF;margin:0 10px" @click="changeVirtualInit(scope.row)" v-if="scope.row.ActivityStateShow=='进行中'">虚拟领取量</div>
						</button-permissions>
						<el-button type="text" class="pointers" @click="lookGiftActive(scope.row,3)" v-if="scope.row.ActivityStateShow=='已结束'">查看</el-button>
						<button-permissions :datas="'deleteassistindex'">
							<div class="table-button" style="color:red;margin:0 10px" @click="delectFun(scope.row)" v-if="scope.row.ActivityStateShow=='已结束'">删除</div>
						</button-permissions>
						<button-permissions :datas="'copyassistindex'">
							<div class="table-button" style="color:#409EFF;margin:0 10px" v-if="scope.row.ActivityStateShow=='已结束'" @click="getNewGift(scope.row,2)">复制</div>
						</button-permissions>
						<el-dropdown style="margin-left:15px" v-if="(scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中')&&(showFinishBtn||showCopyBtn)">
							<span class="el-dropdown-link">
								<span style="color:#409EFF;cursor: pointer;">更多</span>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<buttonPermissions :datas="'finishassistindex'" @isShow='isShowFinish'>
										<div style="color:#409EFF;" @click="stopFun(scope.row)">使终止</div>
									</buttonPermissions>
								</el-dropdown-item>
								<el-dropdown-item>
									<buttonPermissions :datas="'copyassistindex'" @isShow='isShowCopy'>
										<div style="color:#409EFF;" @click="getNewGift(scope.row,2)">复制</div>
									</buttonPermissions>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
		<!-- //虚拟领取量 -->
		<el-dialog :title="'虚拟领取量'" :visible.sync="changeVirtualVisi" width="600px" class="eidt_info">
			<div class="liveInfo">
				<el-form :model="VirtualForm" :rules="Virtualrules" ref="VirtualForm" label-width="150px">
					<div style="margin-bottom: 15px;color: #999;font-size: 14px;">虚拟领取量设置成功之后，活动商品的已领取数量=真实领取 + 虚拟领取；仅做商城前台展示</div>
					<el-table :data="VirtualForm.VirtualData" style="width: 100%;" v-loading="Virtualloading" ref="multipleTable">
						<el-table-column label="商品主图" prop="ImgUrl">
							<template slot-scope="scope">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
								<svg-icon v-else icon-class="noImgIcon" />
							</template>
						</el-table-column>
						<el-table-column label="商品名称" prop="Name"></el-table-column>
						<el-table-column label="真实领取" prop="ExchangedCount"></el-table-column>
						<el-table-column label="虚拟领取" prop="VirtualExchangedCount">
							<template slot-scope="scope">
								<el-form-item inline-message :prop="'VirtualData.' +scope.$index + '.VirtualExchangedCount'" :rules='Virtualrules.VirtualExchangedCount'
								 label-width='0' style="margin-bottom: 0px;">
									<div>
										<el-input type="text" v-model="scope.row.VirtualExchangedCount" @input='Onlynumber(scope.row,scope.$index)'></el-input>
									</div>
								</el-form-item>

							</template>
						</el-table-column>
					</el-table>
					<div style="text-align:center;margin-top: 20px;">
						<el-button type="primary" style="width:120px;" @click='savePackagelist("VirtualForm")'> 保存</el-button>
					</div>
				</el-form>
			</div>

		</el-dialog>

		<!-- //参与人名单 -->
		<el-dialog :title="peoplelistitle + '参与人名单'" :visible.sync="peoplelistVisi" width="1300px" class="eidt_info">
			<div class="liveInfo" style="border-top: 1px solid #ddd;padding-top:40px;">
				<div class="filter-container">
					<div class="filter-item">
						<label class="label" style="display:inline-block;width:75px;text-align:right;">关键字: </label>
						<el-input v-model="Keywords" placeholder="姓名,昵称" style="width: 250px;margin-right: 10px;" clearable></el-input>
					</div>
					<div class="filter-item">
						<label class="label">活动进度: </label>
						<el-select v-model="activityState" style="width: 150px;margin-right: 10px;" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in activityStateList" :key="item.id" :label="item.value" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="filter-item">
						<el-button type="primary" @click="activityFilter">查询</el-button>
					</div>
				</div>
				<el-table :data="peoplelist" style="width: 100%;" v-loading="peoplelistloading" ref="multipleTable">
					<el-table-column label="发起人">
						<template slot-scope="scope">
							<div style="display: flex;">
								<div style="margin-right: 5px;">
									<img v-if="scope.row.WxHeadUrl" :src="scope.row.WxHeadUrl" style="width: 40px;">
									<svg-icon v-else icon-class="noImgIcon" />
								</div>
								<div>
									<div>{{scope.row.Name}}</div>
									<div>{{scope.row.Phone}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="发起时间" prop="AddTime"></el-table-column>
					<el-table-column label="商品名称" prop="ProductName"></el-table-column>
					<el-table-column label="活动进度" prop="StateShow"></el-table-column>
					<el-table-column label="助力人数">
						<template slot-scope="scope">
							<div>{{scope.row.HelpNumber}}/{{scope.row.HelpMemberCount}}</div>
						</template>
					</el-table-column>
					<el-table-column label="完成时间" prop="SuccessTime">
						<template slot-scope="scope">
							<div>{{scope.row.SuccessTime?scope.row.SuccessTime:'未完成'}}</div>
						</template>
					</el-table-column>
					<el-table-column label="兑换状态">
						<template slot-scope="scope">
							<div>{{scope.row.IsExchange?'已兑换':'未兑换'}}</div>
						</template>
					</el-table-column>
					<el-table-column label="备注" prop="Remark"></el-table-column>
				</el-table>
				<div style="text-align: right;">
					<el-pagination v-if="Total1" style="margin:20px 0" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
					 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total1">
					</el-pagination>
				</div>
			</div>

		</el-dialog>
		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>
	</div>
</template>

<script>
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes"
	import {
		activityHelpReceiveList,
		activityHelpReceiveClose,
		activityHelpReceiveDelete,
		activityHelpReceivechangeVirtualInit,
		activityHelpReceivechangeVirtual,
		activityHelpReceivepeopleList
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			buttonPermissions,
			batchCods
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var VirtualExchangedCountCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入虚拟领取数量'));
				} else {
					return callback();
				}
			};
			return {
				Keywords:'',
				activityStateList: [{
					id: 0,
					value: '助力中'
				}, {
					id: 1,
					value: '助力成功'
				}, {
					id: 2,
					value: '助力失败'
				}],
				peoplelistInfo: {},
				activityState: null,
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				peoplelist: [],
				peoplelistloading: false,
				peoplelistitle: '',
				peoplelistVisi: false,
				Virtualloading: false,
				VirtualRow: {},
				Virtualrules: {
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					VirtualExchangedCount: [{
						required: true,
						validator: VirtualExchangedCountCheck,
						trigger: 'blur'
					}]
				},
				VirtualForm: {
					VirtualData: [],
				},
				changeVirtualVisi: false,
				weiCodeData: {},
				weiCodeShow: false,
				showFinishBtn: true,
				showCopyBtn: true,
				imgUrl: config.IMG_BASE,
				loading: false,
				searchKey: '',
				searchState: null,
				searchStateList: [{
						id: 0,
						value: '未开始'
					},
					{
						id: 1,
						value: '进行中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				GiftData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
			}
		},
		created() {
			this.getList();
		},
		methods: {
			activityFilter() {
				this.currentPage1 = 1;
				this.getgetExchangeInfo();
			},
			getExchangeCount(record) {
				this.peoplelistInfo = record
				this.peoplelistitle = '[' + record.Name + ']'
				
				console.log(record.Name,'活动标题')
				this.peoplelistVisi = true
				this.getgetExchangeInfo()
			},
			//获取参与人名单
			async getgetExchangeInfo() {
				try {
					this.peoplelistloading = true
					let data = {
						Id: this.peoplelistInfo.Id,
						Keywords: this.Keywords,
						State: this.activityState,
						Skip: (this.currentPage1 - 1) * this.pageSize1, // 略过数据
						Take: this.pageSize1, // 取的数据
					}
					let result = await activityHelpReceivepeopleList(data)
					this.Total1 = result.Result.Total;
					this.peoplelist = result.Result.Results || [];
				} catch (err) {

				} finally {
					this.peoplelistloading = false
				}
			},
			savePackagelist(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.Virtualloading = true
							let data = {
								Id: this.VirtualRow.Id,
								ProductList: this.VirtualForm.VirtualData
							}
							let result = await activityHelpReceivechangeVirtual(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.changeVirtualVisi = false
								this.getList()
							}
						} catch (err) {
							this.Virtualloading = false
						} finally {
							this.Virtualloading = false
						}
					} else {

					}
				})
			},
			//只能输入整数
			Onlynumber(obj, index) {
				let newobj = obj.VirtualExchangedCount
				newobj = newobj.replace(/[^\.\d]/g, '');
				newobj = newobj.replace('.', '');
				this.VirtualForm.VirtualData[index].VirtualExchangedCount = newobj
			},
			//虚拟领取量
			async changeVirtualInit(record) {
				try {
					this.VirtualRow = record
					this.Virtualloading = true
					this.changeVirtualVisi = true
					this.$nextTick(function() {
						this.$refs.VirtualForm.clearValidate();
					})
					let data = {
						Id: record.Id
					}
					let result = await activityHelpReceivechangeVirtualInit(data)
					if (result.IsSuccess) {
						this.VirtualForm.VirtualData = result.Result
					}
				} catch (err) {
					this.Virtualloading = false
				} finally {
					this.Virtualloading = false
				}
			},
			isShowCopy(val) {
				this.showCopyBtn = val
			},
			isShowFinish(val) {
				this.showFinishBtn = val
			},
			// 推广
			weiCodeFun(record) {

				let fullId = record.Id
				// let proId = record.ProductId
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pageA/pages/activity/help/list`,
					Scene: `activityId=${fullId}`,
					// InputVaule: 'pages/groupDetail/groupDetail?fullId=' + fullId + '&id=' + proId
				}
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						ActivityState: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await activityHelpReceiveList(data)
					this.Total = result.Result.Total;
					this.GiftData = result.Result.Results || [];

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			goOrderpage(record) {
				this.$router.push({
					path: '/order/orderMange',
					query: {
						ActivityId: record.Id ? record.Id : 0,
						ActivityType: 11
					}
				});
			},
			// 查看活动
			lookGiftActive(item, checkindex) {
				this.$router.push({
					path: '/market/Assistoreceive/Editassistindex',
					query: {
						Id: item.Id ? item.Id : 0,
						check: checkindex
					}
				});

			},

			// 添加
			getNewGift(item, type) {
				// 添加/复制是2 , 编辑是1
				this.$router.push({
					path: '/market/Assistoreceive/Editassistindex',
					query: {
						Id: item.Id ? item.Id : 0,
						type: type,
						status: item.ActivityStateShow ? item.ActivityStateShow : ''
					}
				});

			},
			rowKeys(row) {
				return row.Id
			},
			// 删除
			delectFun(row) {
				let message = '是否确认删除本场活动？删除后不可恢复'
				this.$confirm(message, '', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let data = {
						Id: row.Id
					}
					let result = await activityHelpReceiveDelete(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						let currentPageTotal = this.Total - (this.currentPage - 1) * this.pageSize
						if (currentPageTotal == 1 && this.currentPage != 1) {
							this.currentPage -= 1
						}
						this.getList();
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					// this.goodsFilter();
				})
			},
			// 使终止
			stopFun(row) {
				let message = '活动终止后：<br/>你将不可再对这场活动进行编辑<br/>是否确认使终止'
				this.$confirm(message, '', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let result = await activityHelpReceiveClose({
						Id: row.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					this.goodsFilter();
				})
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getgetExchangeInfo();
			},
			
			// 翻页
			handleCurrentChange1(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage1 = val;
				this.getgetExchangeInfo();
			},
		}
	}
</script>

<style lang="less">
	.association {
		padding: 10px;
		background: #fff;

		::v-deep .el-form-item__error {
			position: initial !important;
		}

		.product-info>img {
			width: 64px;
			height: 64px;
		}

		.table-button {
			color: #409EFF;
			display: inline-block;
			margin-right: 15px;
			cursor: pointer;
		}

		.pointers {
			cursor: pointer;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.ellipsisStyle {
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
</style>
